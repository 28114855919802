.categories-list{
    padding: 0 30px;
}
.categories-list .MuiToolbar-gutters{
    padding-left: 0 !important;
}

.categories-list .MuiToolbar-root{
    margin-bottom: 25px;
}

.categories-list a{
    text-decoration: none;
}

.categories-list .MuiTypography-subtitle1{
    font-weight: bold;
}